<template>
  <div class="members-directory">
    <div class="panel-inr-box">
      <div class="box-top-wrap box-mobile-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button @click="$router.go(-1)" class="text-capitalize
              btn customBtn pattern-btn
              white--text
            " elevation="0">
            <span>{{ $t("carHistory.back") }}</span>
          </button>
          <div>
            <h3 class="box-title green-text">
              {{ $t("carReport.title") }}
            </h3>
            <!-- <small>Vin No: {{ vinHtmlReportInfo.data.vin_number }}</small> -->
            <p class="box-subtitle">{{ $t("carReport.carPerotInfo") }}</p>
          </div>
        </div>
        <!-- <v-spacer v-if="$vuetify.breakpoint.width > 500" />
        <small class="middle-text">For a better and complete view, please click here to download this
          report.</small> -->
        <v-spacer v-if="$vuetify.breakpoint.width > 500" />
        <div class="add-vehicle-top-btn">
          <button class="btn customBtn pattern-btn white--text" elevation="0" @click="expandToggle">
            <span><v-icon>mdi-email</v-icon></span>
          </button>
          <a v-if="vinHtmlReportInfo" :href="vinHtmlReportInfo.data.pdf_path" target="_blank" class="btn customBtn pattern-btn white--text ml-2"
            elevation="0">
            <span><v-icon>mdi-printer</v-icon></span>
          </a>
        </div>
      </div>
      <div class="custom-card vehicle-car vehicle-car-result">
        <div class="custom-box">
          <v-card v-if="isExpanded" class="vehicle-car-search mb-5" flat>
            <v-text-field hide-details="auto" v-model="email" elevation="0" :error-messages="emailError"
              @blur="$v.email.$touch()" :loading="loading" dense :placeholder="$t('vinSearch.enterEmail')"
              solo></v-text-field>
            <v-btn width="40" medium @click="sendHistoryReport" class="btn customBtn transparent-btn white--text"
              elevation="0">
              <!-- {{ $t("vinSearch.send") }} -->
              <span><img src="./../assets/img/send.svg" alt="" /></span>
            </v-btn>
          </v-card>
          <template class="pa-4" v-if="vinHtmlReportInfo">
            <vue-pdf-app :pdf="vinHtmlReportInfo.data.pdf_path"></vue-pdf-app>
            <!-- <vue-pdf-app pdf="http://rdl-member-apis.test/storage/3/1696503330.pdf"></vue-pdf-app> -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import { mapGetters, mapActions } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
  components: {
    VuePdfApp,
  },
  data() {
    return {
      loading: false,
      email: null,
      isExpanded: false,
      errors: {
        email: null,
      },
      adobeKey: process.env.VUE_APP_ADOBE_KEY,
      adobeApiPDFReady: false,
      adobeDCView: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters({
      vinHtmlReportInfo: "vehicleHistory/getVinHtmlReportInfo",
    }),
    emailError() {
      let error = null;
      if (this.$v.email.$dirty && !this.$v.email.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("carHistoryReport.email"),
        });
      }
      if (this.$v.email.$dirty && !this.$v.email.email) {
        error = this.$t("carHistoryReport.emailIsInvalid", {
          field: this.$t("carHistoryReport.email"),
        });
      }
      if (this.errors && this.errors.email) {
        error = this.errors.email;
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendHistoryReportMail: "carHistoryReport/sendHistoryReportMail",
    }),

    expandToggle() {
      if (this.isExpanded) {
        this.isExpanded = false;
        this.email = null;
        this.apiErrorMessage = null;
      } else {
        this.isExpanded = true;
      }
    },

    async sendHistoryReport() {
      this.loading = true;
      this.$v.$touch();

      try {
        await this.sendHistoryReportMail({
          vehicle_history_id: this.vinHtmlReportInfo.data.id,
          email: this.email,
        });
        this.expandToggle();
      } catch ({ message }) {
        this.errors.email = message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
